import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Eng001 from "../../engine/eng001";
import TableSecondaryData from "../../comcom/TableSecondaryData";
import Form1 from '../../comcom/Form1';


const Pharm3plus1 = ({IDORG}) =>{
    const [isOpenAdd, setIsOpenAdd] = React.useState(false);
    const toggleAdd = () => setIsOpenAdd(!isOpenAdd);
    const [TPlist,setTPlist] = React.useState(false)


    const loadData = async ()=>{
        let d = await Eng001.load3plus1({IDORG})
        setTPlist(d['TP'])
        return d['R']
    }
    const addToAction = async (p)=>{
        let param = {IDORG}
        //console.log(p)
        let IDSMPL_TPM = []
        for(let i=0;i<TPlist.length;i++){
            if(p.formData["TP_"+TPlist[i]['IDSMPL_TPM']]){
                IDSMPL_TPM.push(TPlist[i]['IDSMPL_TPM']) 
            }
        }
        param = {IDORG,IDSMPL_TPM}
        //console.log(param)
        setIsOpenAdd(false)
        return await Eng001.addTo_3_plus_1(param)
    }

    const pH=['Рік','Місяць','Підтв','Приз','Кільк']
    const pF=['YY','MM','DTPODTV2','SHORT_NAME','KOL_V']   

    const AddFormFields = []
    for(let i=0;i<TPlist.length;i++){
        
        AddFormFields.push({"fname":"TP_"+TPlist[i]['IDSMPL_TPM'],
            "ftype":"checkboxLeft",
            "fcaption":TPlist[i]['TPM'],
            "fval":''
        })
    }
    const formAdd = {
        caption : "",
        buttonCaption : "Додати",
        cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : AddFormFields,
        action : addToAction,
        actionCancel : toggleAdd,
        clearData : true,
        localStorageTag : 'form1',
    }     

    return <>
 <Modal isOpen={isOpenAdd} toggle={toggleAdd}>
        <ModalHeader toggle={toggleAdd}>Додаємо в Акцію ДМ</ModalHeader>
        <ModalBody>
            <Form1 formCFG={formAdd}  />
        </ModalBody>
      </Modal>

        <TableSecondaryData caption={'Акції ДМ'}
            loadDataFun={loadData}
            Param={{IDORG}}
            Fields={pF}
            ColumnCaption={pH}
            DodButtonCaption={'+в поточн місяць'}
            DodButtonAction = {toggleAdd}
        />
    </> 
}

export default Pharm3plus1